import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0072 = (props: Props): JSX.Element => {
  const routePram = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0034/'
  )
  const routePram2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0070/'
  )
  const routePram3 = useRouteParam('https://www.kintaicloud.jp/')
  return (
    <Wrapper>
      <ColumnH1 label="有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.1.12</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0072.jpg"
          alt="キンクラコラム画像72"
        />
        <p>
          働き方改革が推進されている現在、従業員の有給休暇の取得が多くの企業で課題として挙がってきています。
          <br />
          特に、2019年の労働基準法の改正により、
          <span>
            年10日以上の有給休暇が付与されている従業員は必ず5日取得させることが義務化
          </span>
          されました。
          <br />
          もし、違反すれば罰則などのリスクがあるため、各従業員がどれくらい有給を取得しているのか把握するために、有給管理をしていく必要があります。
          <br />
          今まではExcel(エクセル)での管理でも問題はなかったが、従業員の増加により管理が難しくなってきたため、有給管理のできる勤怠管理システムの導入を検討している方も多いでしょう。
          <br />
          とはいえ、勤怠管理システムを導入しようにも、「どのようなシステムを導入すればいいのかわからない」という方も多いのではないでしょうか。
          <br />
          本記事では、勤怠管理システムのメリットやデメリットから、お勧めのシステムのご紹介をしていきます。
        </p>
        <h1 id="toc1">有給管理の重要性と勤怠管理システムが必要になる理由</h1>
        <p>
          そもそもなぜ有給管理が必要なのでしょうか。また有給管理をするのであれば、なぜ勤怠管理システムの導入が必要になるのか、システム導入の必要性について詳しく解説していきます。{' '}
        </p>
        <a href={routePram} className="column-detail-link">
          関連記事：有給休暇がもらえない？休職時における有給休暇の付与について
        </a>
        <h2 id="toc1-1">有給管理が重要な理由</h2>
        <p>
          2019年の労働基準法の改正により、年10日以上の有給休暇が付与されている従業員は、必ず5日取得させることが義務化されました。これに違反すると、6ヵ月以下の懲役または30万円以下の罰金が発生します。また、
          <span>
            労働者とのトラブルや、労働者の雇用条件や労働環境に対する評価が下がり、企業イメージの悪化
          </span>
          につながることも想定されます。
          <br />
          <br />
          そのため、従業員がどれだけ有給休暇を取得しているのかを把握し、適切に管理することが重要となります。
        </p>
        <h2 id="toc1-2">Excelで有給管理をするのは難しい</h2>
        <p>
          従業員が増えれば増えるほど、有給休暇の管理は難しくなります。
          <br />
          Excelで管理を行う場合、手動による入力が必要となるため、入力漏れなどのリスクが伴うでしょう。
          <br />
          また、複数人での同時利用や、共有・更新が難しいという問題もあります。
          <br />
          そのため、Excelでの有給管理は難しく、効率的ではありません。
        </p>
        <h2 id="toc1-3">効率的に有給管理するならExcelよりも勤怠管理システム</h2>
        <p>
          勤怠管理システムを導入することで、有給管理を効率的に行うことが可能になります。
          <br />
          しかし、システム選定の際には、
          <span>自社のニーズや予算、従業員の数</span>
          などを考慮することが重要です。
          <br />
          また、<span>システムの操作性やサポート体制</span>
          も重要な選定基準となります。
          <br />
          最適なシステムを選定し、有給管理を効率化しましょう。
          <br />
          <br />
          以上が有給休暇を管理するために、勤怠管理システムが必要になる理由です。
          <br />
          では、勤怠管理システムはどのようなものを導入すればいいのでしょうか。次の項目で詳しく解説します。
        </p>
        <a href={routePram2} className="column-detail-link">
          参照記事：Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？
        </a>
        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}
        <h1 id="toc2">勤怠管理システムを選ぶ際のポイント</h1>
        <img
          src="/images/column/details/c0072_g01.jpg"
          alt="キンクラコラム画像72"
        />
        <p>
          有給管理を効率的に行うのであれば、勤怠管理システムを活用することが最適でしょう。
          <br />
          しかし、勤怠管理システムを導入しようにも、「どのようなシステムを選定すればいいのかわからない」という方もいるのではないでしょうか。
          <br />
          ここでは、勤怠管理システムの選び方について解説していきます。
        </p>
        <h2 id="toc2-1">有給管理ができる</h2>
        <p>
          勤怠管理を導入したとしても、有給管理機能がついていなければ当然、有給管理を効率化することは難しいです。
          <br />
          そのため、有給管理機能がついているかどうかを確認する必要があります。
        </p>
        <h2 id="toc2-2">料金</h2>
        <p>
          勤怠管理システムの料金は、利用する人数や規模により異なります。
          <br />
          ただし、コストだけを重視するのではなく、自社の要件を満たすプランが提供されているかどうかを確認することが大切です。
          <br />
          <span>自社にとって必要な機能の有無</span>
          を中心に探すことで、最適なシステムを選定できるでしょう。
        </p>
        <h2 id="toc2-3">操作性</h2>
        <p>
          操作性は、勤怠管理システムを選ぶ際の重要な要素です。
          <br />
          システムが複雑で直感的に操作できない場合、機能性がいかに優れていても、従業員が使いこなせず、結果的に非効率になる可能性があります。
          <br />
          そのため、システムを導入する前に、無料トライアルなどを活用して、
          <span>すべての従業員が使いやすいか</span>
          どうかを確認しておきましょう。
        </p>
        <h2 id="toc2-4">自社の勤務体制に合っている</h2>
        <p>
          企業により固定労働制ではなく、変形労働制、フレックスタイム制、シフト制などの勤務形態を採用している企業も多いでしょう。
          <br />
          また、人材派遣やSESといった客先常駐で勤務している場合、クライアント企業の勤務体制に合わせる場合もあるため、従業員の勤務形態がそれぞれ違うといったこともあります。
          <br />
          そのため、勤怠管理システムが自社の<span>勤務体制に合っているか</span>
          どうかを確認する必要があります。
        </p>
        <h2 id="toc2-5">有給休暇の取得単位が自社に合っているか</h2>
        <p>
          有給休暇の取得単位は、企業により大きく異なります。
          <br />
          ある企業では時間単位での取得を許可している一方で、他の企業では半日単位や1日単位での取得を基本としていることもあります。
          <br />
          <br />
          さらに、長期間の勤続により付与される休暇や記念日休暇など、企業独自の休暇制度を導入している場合があります。
          <br />
          そのため<span>自社の就業規則に適合する勤怠管理システムを選ぶ</span>
          ことが重要です。
          <br />
          <br />
          以上のポイントを意識することで最適な勤怠管理システムを選定することが可能になります。
        </p>
        <h1 id="toc3">
          有給管理を効率的に行うなら勤怠管理システム「キンクラ」の導入がおすすめ
        </h1>
        <p>
          <span>有給管理を効率的に行うなら、勤怠管理システムを導入する</span>
          のが良いでしょう。
          <br />
          本記事で解説した勤怠管理システム際のポイントを意識し選定することで、自社に合ったシステムを導入することが可能になります。
          <br />
          しかし、勤怠管理システムを実際に導入しても、自社の環境に合っていなかったというリスクも少なからずあります。
          <br />
          そこでお勧めなのが「キンクラ」です。
          <br />
          「キンクラ」は有給管理にも対応しており、「無料トライアル」も実施しています。
          <br />
          また、クラウド型のシステムのため、PCだけではなくスマホからの入力も可能で、「場所を問わず」勤怠入力が可能です。
          <br />
          もし、勤怠管理システムの導入を検討しているならば、一度「キンクラ」を試してみませんか？
          <br />
          <br />
        </p>
        <a href={routePram3} className="column-detail-link">
          ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
        </a>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0072
